<template>
  <div>
    <b>
      {{ label }}:
    </b>
    <div v-if="isArray">
      <order-event-context-line v-for="item in arrayValues" v-bind="item" :key="item.label" class="ml-5" />
    </div>
    <span v-else>
      {{ value }}
    </span>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  label: string
  value?: string | Array<{ label: string, value: string }>
}

const props = withDefaults(defineProps<Props>(), {
  value: ''
})

const arrayValues = computed(() => {
  if (typeof props.value === 'string') {
    return []
  }
  return props.value
})
const isArray = computed(() => Array.isArray(props.value))
</script>
