<template>
  <editable-text
    v-bind="$attrs"
    v-model="referenceName"
    :maxlength="ORDER_NAME_MAX_LENGTH"
    @update:model-value="update"
  />
</template>

<script setup>
import { ref, watch } from 'vue'
import { ORDER_NAME_MAX_LENGTH } from '@/constants'

const props = defineProps({
  order: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['update:order'])

const referenceName = ref(props.order.reference_name)
watch(() => props.order.reference_name, (value) => {
  referenceName.value = value
})

const nuxtApp = useNuxtApp()
const update = async () => {
  try {
    const order = await nuxtApp.$api.v1.rfqs.update({ id: props.order.id, reference_name: referenceName.value })

    emit('update:order', order)
  }
  catch (error) {
    referenceName.value = props.order.reference_name
    nuxtApp.$error.report(error)
  }
}
</script>
