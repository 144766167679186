<template>
  <v-list-item v-bind="$attrs" active-class="active-item">
    <template v-slot:prepend>
      <v-badge
        :content="countBadge"
        :model-value="!!countBadge"
        class="custom-badge"
        color="red"
        location="bottom right"
        :offset-x="offset"
        overlap
      >
        <v-icon class="item-icon" :color="iconColor">
          {{ icon }}
        </v-icon>
      </v-badge>
    </template>
    <v-list-item-title class="item-content">
      {{ label }}
    </v-list-item-title>
  </v-list-item>
</template>

<script setup lang="ts">
export interface Props {
  countBadge?: number
  icon: string
  iconColor?: string
  label: string
}

const props = defineProps<Props>()

const offset = computed(() => (props.countBadge && props.countBadge > 99 ? 2 : -2))
</script>

<style lang="scss" scoped>
$active-color: rgb(var(--v-theme-interactive));
$base-color: rgb(var(--v-theme-mid-grey));

.child-item .item-content {
  font-weight: normal;
}

.child-item,
.item-content,
.item-icon {
  color: $base-color;
  transition: color 0.3s swing;
}

.active-item,
.active-item .item-content,
.active-item .item-icon {
  color: $active-color;
}
</style>
<style lang="scss">
.custom-badge span {
  padding: 2px 4px;
}
</style>
