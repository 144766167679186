<template>
  <qtm-autocomplete
    v-model="order.agent"
    dense
    hide-details
    :item-title="userName"
    :items="users"
    :loading="loading"
    placeholder="Assign To"
    return-object
    @update:model-value="updateAgent"
  >
    <template v-slot:no-data>
      <v-list-item>
        No available agents
      </v-list-item>
    </template>
  </qtm-autocomplete>
</template>

<script setup lang="ts">
import type { Order, User } from '@quotetome/materials-api'

const props = defineProps<{
  order: Order
}>()

const loading = ref(false)
const users = ref<User[]>([])

const { $api, $error, $toast } = useNuxtApp()
const fetchAgents = async () => {
  loading.value = true
  try {
    const responseUsers = await $api.v1.users.list({ permission: 'qtm_can_act_as_buyer' })

    responseUsers.sort(
      (a: any, b: any) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name)
    )
    users.value = responseUsers
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

onMounted(() => {
  fetchAgents()
})

const adminStore = useAdminStore()
const updateAgent = async ({ id }: { id: number }) => {
  if (!id) {
    return
  }

  const updateData = {
    id: props.order.id,
    agent: id,
  }

  loading.value = true
  try {
    const updatedOrder = await $api.v1.rfqs.update(updateData as any)
    adminStore.updateOrder(updatedOrder)
    $toast.success('Agent assigned')
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const userName = (user: User) => `${user.first_name} ${user.last_name}`
</script>
