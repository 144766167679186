<template>
  <div class="mt-4">
    <nuxt-link
      target="_blank"
      :to="{ name: 'companies-id', params: { id: companyId } }"
    >
      {{ companyName }}
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '@quotetome/materials-api'

const { $api, $error } = useNuxtApp()
const companyName = ref('')
const props = defineProps<{
  order: Order
}>()

const companyId = computed(() => {
  return props.order.jobsite?.company ?? props.order.owner?.company.id
})

const fetchCompany = async () => {
  try {
    const company = await $api.v1.companies.get(companyId.value)
    companyName.value = company.name as string
  }
  catch (error) {
    $error.report(error)
  }
}

onMounted(fetchCompany)
</script>
