<template>
  <v-dialog
    v-model="showActionDialog"
    :fullscreen="fullscreen"
    min-height="95vh"
    :max-width="fullscreen ? undefined : '100rem'"
    persistent
    scrollable
  >
    <v-card v-if="activeOrder" class="action-dialog">
      <v-card-title
        class="d-flex justify-space-between pb-0"
        :class="{ 'cancelled-order': activeOrder.cancelled, 'integrated-background': integrated }"
      >
        <div>
          <order-priority-chip clickable :order="activeOrder" size="small" />
          <editable-order-name
            v-if="activeOrder.reference_name"
            class="mr-1"
            :disabled="!canChangeName"
            :order="activeOrder"
            @update:order="updateOrder"
          />
          <order-type-chip-menu clickable :order="activeOrder" size="small" />
          <cancelled-chip :order="activeOrder" size="small" />
          <div class="mt-2 qtm-body-small">
            <div class="text-mid-grey">
              {{ activeOrder.qtm_reference_number }} - {{ activeOrder.state ? activeOrder.state.internal_name : '' }}
              <span v-if="activeOrder.transition_timestamp">
                - {{ timeDelta }} in state
              </span>
            </div>
            <admin-link :to="`rfqs/order/${activeOrder.id}/change/`">
              Open in Admin
            </admin-link>
            <reminder-notification-btn v-if="activeOrder.owner" :order="activeOrder" />
          </div>
        </div>

        <div v-if="activeOrder" class="qtm-body-small">
          <div v-if="activeOrder.date_closes">
            <div class="text-mid-grey qtm-overline">
              Quote Due
            </div>
            <div>
              {{ dateCloses }}
            </div>
          </div>
          <div v-if="activeOrder.delivery_date">
            <div class="text-mid-grey qtm-overline">
              Delivery Date
            </div>
            <div>
              {{ deliveryDate }}
            </div>
          </div>
        </div>

        <div class="d-flex mr-n2 mt-n2 qtm-body-small">
          <div v-if="company">
            <linked-company-name :order="activeOrder" />
            <div class="text-mid-grey" v-text="activeOrder.owner.company.purchasing_email" />
          </div>
          <qtm-icon-btn
            color="mid-grey"
            :icon="pinIcon"
            :loading="loading"
            :title="isOrderPinned ? 'Unpin' : 'Pin'"
            @click="pinOrder"
          />
          <qtm-icon-btn
            color="mid-grey"
            icon="mdi-refresh"
            :loading="loading"
            title="Refresh"
            @click="refreshOrder"
          />
          <qtm-icon-btn
            color="mid-grey"
            :icon="fullscreen ? 'mdi-window-restore' : 'mdi-window-maximize'"
            title="Resize"
            @click="toggleFullscreen"
          />
          <qtm-icon-btn
            color="mid-grey"
            icon="mdi-close"
            title="Close"
            @click="showActionDialog = false"
          />
        </div>
      </v-card-title>

      <v-divider class="mb-4" />

      <v-card-text v-if="!activeOrder.lastFullRefresh">
        <qtm-skeleton />
      </v-card-text>
      <v-card-text v-else class="dialog-content pa-2">
        <order-action-sidebar v-if="showSidebar" class="action-dialog-sidebar" :order="activeOrder" />

        <div v-if="showAttachment" class="action-dialog-attachmet-viewer">
          <attachment-viewer
            ref="pdfViewer"
            select-text-layer
            :src="documentToPreviewSrc"
            :name="documentToPreviewName"
            @close="showAttachment = false"
          />
        </div>

        <div class="action-dialog-component flex-grow-1 pr-4">
          <v-tabs
            v-model="tab"
            bg-color="background"
            class="action-dialog-tabs text-mid-light-grey"
            color="interactive"
          >
            <v-tab
              v-for="t in tabs"
              :key="t.key"
              class="text-uppercase"
              selected-class="text-interactive v-tab--selected"
            >
              {{ t.label }}
            </v-tab>
          </v-tabs>
          <v-window v-model="tab" class="action-dialog-content bg-background">
            <v-window-item key="main">
              <component
                :is="contentComponent"
                ref="activeComponent"
                :half-width="showAttachment"
                :order="activeOrder"
                @preview="showDocumentPreview($event)"
              />
            </v-window-item>
            <v-window-item key="inbound-emails">
              <view-emails :order="activeOrder" type="inbound" :visible="tab === 1" />
            </v-window-item>
            <v-window-item key="outbound-emails">
              <view-emails :order="activeOrder" type="outbound" :visible="tab === 2" />
            </v-window-item>
            <v-window-item key="attachments">
              <view-attachments :order="activeOrder" @preview="showDocumentPreview($event)" />
            </v-window-item>
            <v-window-item key="event-log">
              <order-event-timeline :now="now" :order="activeOrder" :visible="tab === 4" />
            </v-window-item>
          </v-window>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-space-between">
        <common-actions :order="activeOrder" />
        <order-state-actions
          :execute-hook="executeActionHook"
          :order="activeOrder"
          @refresh-component="refreshComponent"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref } from 'vue'
import { mapActions, mapState } from 'pinia'
import { DateTime, OrderState } from '@quotetome/materials-api'
import { dateMonth, objectDeliveryDate } from '@/models/filters'
import AdminLink from '@/components/admin/admin-link.vue'
import AttachmentViewer from '@/components/attachments/attachment-viewer.vue'
import CancelledChip from '@/components/admin/cancelled-chip.vue'
import CommonActions from '@/components/admin/actions/common-actions.vue'
import EditableOrderName from '@/components/orders/editable-order-name.vue'
import LinkedCompanyName from '@/components/admin/linked-company-name.vue'
import OrderTypeChipMenu from '@/components/admin/order-type-chip-menu.vue'
import OrderActionSidebar from '@/components/admin/order-action-sidebar.vue'
import OrderEventTimeline from '@/components/admin/order-event-timeline.vue'
import OrderPriorityChip from '@/components/admin/order-priority-chip.vue'
import OrderStateActions from '@/components/admin/order-state-actions.vue'
import ReminderNotificationBtn from '@/components/admin/reminder-notification-btn.vue'
import { usePins } from '@/composables/pins'
import useTimeDelta from '@/composables/time-delta'
import ViewAttachments from '@/components/admin/view-attachments.vue'
import ViewEmails from '@/components/admin/emails/view-emails.vue'

// 1 minute
const nowUpdateInterval = 60 * 1000

export default {
  name: 'order-action-dialog',
  components: {
    AdminLink,
    AttachmentViewer,
    CancelledChip,
    CommonActions,
    EditableOrderName,
    ReminderNotificationBtn,
    LinkedCompanyName,
    OrderTypeChipMenu,
    OrderActionSidebar,
    OrderEventTimeline,
    OrderPriorityChip,
    OrderStateActions,
    ViewAttachments,
    ViewEmails,
  },
  setup() {
    const adminStore = useAdminStore()
    const activeOrder = computed(() => adminStore.activeOrder)
    const stateTransitionTime = computed(
      () => (activeOrder.value ? new DateTime(activeOrder.value.transition_timestamp * 1000) : null)
    )
    const now = ref(new DateTime())
    const timeDelta = useTimeDelta(now, stateTransitionTime)

    const { addPin, isPinned, removePin } = usePins()

    return {
      addPin, activeOrder, isPinned, now, removePin, timeDelta
    }
  },
  data() {
    return {
      documentToPreviewSrc: null,
      documentToPreviewName: null,
      fullscreen: false,
      loading: false,
      nowUpdateTimer: undefined,
      showAttachment: false,
      tab: 0,
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isStaff']),

    canChangeName() {
      return [
        OrderState.INVITING_VENDORS,
        OrderState.WAITING_FOR_QUOTES,
        OrderState.QUOTE_QA,
        OrderState.WAITING_FOR_PURCHASE_ORDER,
        OrderState.FINAL_ADJUSTMENTS,
        OrderState.WAITING_FOR_APPROVAL,
      ]
        .map(state => state.id)
        .includes(this.activeOrder?.state?.id)
    },

    company() {
      return this.activeOrder?.owner?.company
    },

    dateCloses() {
      return dateMonth(this.activeOrder?.date_closes)
    },

    deliveryDate() {
      return objectDeliveryDate(this.activeOrder?.pos?.[0] || this.activeOrder)
    },

    integrated() {
      return !!this.activeOrder?.jobsite?.accounting_id
    },

    isOrderPinned() {
      return this.isPinned(this.activeOrder?.id, 'order')
    },

    pinIcon() {
      return this.isOrderPinned ? 'mdi-pin-off' : 'mdi-pin'
    },

    showActionDialog: {
      get() {
        return !!this.activeOrder && this.isStaff
      },

      set() {
        this.showAttachment = false
        this.documentToPreviewSrc = null
        this.documentToPreviewName = null
        this.activateOrder(undefined)
      }
    },
    showSidebar() {
      return !this.showAttachment
    },
    contentComponent() {
      if (this.activeOrder.state) {
        const component = this.activeOrder.state.dialog_component

        if (typeof component === 'function') {
          return component(this.activeOrder)
        }
        return component
      }
      return undefined
    },
    tabs() {
      return [
        { label: 'Main', key: 'main' },
        { label: 'Inbox', key: 'inbound-emails' },
        { label: 'Sent Emails', key: 'outbound-emails' },
        { label: 'Attachments', key: 'attachments' },
        { label: 'Event Log', key: 'event-log' },
      ]
    },
  },
  watch: {
    activeOrder: {
      immediate: true,
      async handler(newOrder, oldOrder) {
        if (this.$route.query.activeOrder?.toString() !== newOrder?.id?.toString() && newOrder?.id !== oldOrder?.id) {
          this.$router.replace({
            ...this.$route,
            query: {
              activeOrder: this.activeOrder?.id,
            }
          })
        }

        if (!newOrder) {
          this.tab = 0
          return
        }
        if (oldOrder && (newOrder.id === oldOrder.id) && newOrder.lastFullRefresh) {
          return
        }
        try {
          const order = await this.$api.v1.rfqs.get(this.activeOrder.id)
          this.updateOrder(order)
        }
        catch (error) {
          this.$error.report(error)
        }
      }
    }
  },
  mounted() {
    this.nowUpdateTimer = setInterval(() => { this.now = new DateTime() }, nowUpdateInterval)
    if (this.$route.query.activeOrder) {
      this.activateOrder({ id: parseInt(this.$route.query.activeOrder, 10) })
    }
  },
  beforeUnmount() {
    clearInterval(this.nowUpdateTimer)
  },
  methods: {
    ...mapActions(useAdminStore, ['activateOrder', 'updateOrder']),

    executeActionHook(hookName, action, abort) {
      const component = this.$refs.activeComponent
      if (!component || !component[hookName]) {
        return undefined
      }
      return component[hookName](action, abort)
    },
    pinOrder() {
      if (this.isOrderPinned) {
        this.removePin(this.activeOrder.id, 'order')
      }
      else {
        this.addPin({
          title: this.activeOrder.reference_name,
          type: 'order',
          objectId: this.activeOrder.id,
        })
      }
    },
    async refreshComponent() {
      const activeComponent = this.$refs.activeComponent

      if (activeComponent && activeComponent.refresh) {
        // Allow the order to be set before refreshing components that need it
        await this.$nextTick()
        activeComponent.refresh()
      }
    },
    async refreshOrder() {
      if (this.activeOrder) {
        this.loading = true
        try {
          const refreshedOrder = await this.$api.v1.rfqs.get(this.activeOrder.id)
          this.updateOrder(refreshedOrder)
          await this.refreshComponent()
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
    },
    showDocumentPreview(attachment) {
      this.documentToPreviewName = attachment.name
      this.documentToPreviewSrc = attachment.url
      this.showAttachment = true
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
    }
  }
}
</script>

<style lang="scss" scoped>
.action-dialog {
  background-color: rgb(var(--v-theme-background));
}

.v-card .dialog-content {
  color: inherit;
}

.order-type-chip {
  margin-left: 1rem;
}

.admin-link {
  font-weight: bold;
}

.v-card .dialog-content {
  color: inherit;
  display: flex;
  padding: 0 0 1rem 0.25rem;
}

.action-dialog-sidebar {
  overflow-y: auto;
  max-width: 20rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.action-dialog-attachmet-viewer {
  flex-shrink: 0;
  overflow-y: auto;
  width:48%;
}

.action-dialog-component {
  overflow-y: auto;
  min-width: 50%;
}

.action-dialog-tabs {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.action-dialog-content {
  padding-top: 50px;
}

.cancelled-order {
  border-top: solid 6px rgb(var(--v-theme-error));
}

.integrated-background {
  background-color: rgb(var(--v-theme-sand));
}

.v-tabs-items {
  padding-top: 10px;
}
</style>
