import type { DateTime } from '@quotetome/materials-api'

const UNITS = ['days', 'hours', 'minutes']

export default function useTimeDelta(current: DateTime, past: DateTime) {
  return computed(() => {
    for (let i = 0; i < UNITS.length; i += 1) {
      const unit = UNITS[i]
      const delta = unref(current).diff(unref(past), unit as any)

      if (delta) {
        const pluralUnit = delta === 1 ? unit.slice(0, -1) : unit

        return `${delta} ${pluralUnit}`
      }
    }

    return '<1 minute'
  })
}
