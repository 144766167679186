<template>
  <div v-if="!order.lastFullRefresh">
    <qtm-skeleton />
  </div>
  <div v-else class="order-state-actions">
    <qtm-btn
      v-if="primaryAction"
      class="action-button"
      :disabled="isDisabled(primaryAction)"
      :loading="loading"
      :title="actionTip(primaryAction)"
      @click="actionSelected(primaryAction)"
    >
      {{ primaryAction.name }}
    </qtm-btn>

    <v-menu v-if="showMenu" content-class="qtm-border">
      <template v-slot:activator="{ props: activator }">
        <qtm-icon-btn v-bind="activator" icon="mdi-dots-vertical" />
      </template>

      <v-list>
        <v-list-item
          v-for="action in secondaryActions"
          :key="action.name"
          class="action-button"
          :disabled="isDisabled(action)"
          :title="actionTip(action)"
          @click="actionSelected(action)"
        >
          <v-list-item-title>
            {{ action.name }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!order.isInStorePO"
          :loading="loading"
          @click="duplicateOrderDialog = true"
        >
          <v-list-item-title>
            Duplicate Order
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirmation-dialog
      v-if="showConfirmationDialog"
      v-model="showConfirmationDialog"
      cancel-button-text="No"
      ok-button-text="Yes"
      :title="confirmOptions.title"
      :width="confirmOptions.width || '25rem'"
      @close="closeConfirmDialog"
      @confirm="takeAction(confirmOptions.action)"
    >
      <component
        :is="confirmOptions.component"
        v-if="confirmOptions.component"
        :order="order"
        :text="confirmOptions.text"
        @update-action="updateAction(confirmOptions.action, $event)"
      />
      <span v-else>{{ confirmOptions.text }}</span>
    </confirmation-dialog>
    <qtm-dialog-card v-model="duplicateOrderDialog" title="Duplicate Order?">
      Duplicated order will include order attachments, order items, quotes, quote items, and quote attachments.
      It will be place into the Order Submitted state.
      <div class="mt-3">
        <qtm-checkbox v-model="moveEmailsToDuplicate" label="Move Emails to Duplicate?" />
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" tertiary xlarge @click="duplicateOrderDialog = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="loading" @click="duplicateOrder">
          Duplicate Order
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </div>
</template>

<script setup lang="ts">
import merge from 'lodash.merge'
import type { Order } from '@quotetome/materials-api'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'

export interface Props {
  order: Order
  executeHook: Function
}

const props = defineProps<Props>()
const emit = defineEmits(['refresh-component'])

const adminStore = useAdminStore()

const confirmOptions = ref<any>({})
const duplicateOrderDialog = ref(false)
const loading = ref(false)
const moveEmailsToDuplicate = ref(false)
const showConfirmationDialog = ref(false)

const actions = computed(() => {
  if (!props.order.state.actions) {
    return []
  }

  return props.order.state.actions.filter(action => (action.condition ? action.condition(props.order) : true))
})
const closeAction = computed(() => {
  const matches = actions.value.filter(action => action.event === 'close')

  if (matches.length) {
    return matches[0]
  }

  return undefined
})
const primaryAction = computed(() => {
  if (props.order.cancelled && closeAction.value) {
    return closeAction.value
  }

  return actions.value[0]
})
const secondaryActions = computed(() => {
  if (props.order.cancelled && closeAction.value) {
    return actions.value.filter(action => action !== closeAction.value)
  }

  return actions.value.slice(1)
})
const showMenu = computed(() => secondaryActions.value.length > 0 || !props.order.isInStorePO)

const { $api, $error, $toast } = useNuxtApp()

const takeCancelAction = async (action: any) => {
  const newOrder = await $api.v1.rfqs.cancel(
    props.order.id as number,
    {
      cancelled_by: action.cancelledBy,
      cancellation_reason: action.cancellationReason,
      cancellation_message: action.cancellationMessage,
      distribute: action.distribute,
    }
  )

  adminStore.updateOrder(newOrder)

  if (newOrder.isArchived) {
    adminStore.activateOrder(undefined)
  }
}

const takeDeleteAction = async () => {
  loading.value = true
  try {
    await $api.v1.rfqs.delete(props.order)
    adminStore.removeOrder(props.order)
    adminStore.activateOrder(undefined)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const nonTransitionActions = {
  cancel: takeCancelAction,
  delete: takeDeleteAction,
}

const actionSelected = async (action: any) => {
  let aborted = false
  const abort = () => { aborted = true }

  if (isDisabled(action)) {
    return
  }

  if (!action.confirmation) {
    takeAction(action)
    return
  }

  await props.executeHook('beforeConfirmation', action, abort)

  if (!aborted) {
    confirmOptions.value = action.confirmation(props.order)
    confirmOptions.value.action = action
    showConfirmationDialog.value = true
  }
}

const closeConfirmDialog = () => {
  showConfirmationDialog.value = false
  confirmOptions.value = {}
}

const takeAction = async (action: any) => {
  let aborted = false

  // Abort allows the hook to cancel the transition without requiring it to make a call to the backend
  // that it knows will fail or to raise a superfluous error.
  const abort = () => { aborted = true }

  closeConfirmDialog()

  loading.value = true

  if (action.non_transition_state_action) {
    handleNonTransitionAction(action)
    loading.value = false
    return
  }

  try {
    await props.executeHook('beforeTransition', action, abort)
    if (!aborted) {
      const newOrder = await $api.v1.rfqs.transition(props.order, action.event, { actions: action.actions })

      adminStore.updateOrder(newOrder)
      if (newOrder.isArchived) {
        adminStore.activateOrder(undefined)
      }
    }
  }
  catch (error) {
    $error.report(error)
  }

  loading.value = false
}

const handleNonTransitionAction = (action: any) => {
  try {
    nonTransitionActions[action.event as keyof typeof nonTransitionActions](action)
  }
  catch (error) {
    $error.report(error)
  }
}

const isDisabled = (action: any) => {
  if (!action.disable) {
    return false
  }

  return !!action.disable(props.order)
}

const actionTip = (action: any) => {
  if (!action.disable) {
    return undefined
  }

  return action.disable(props.order)
}

const updateAction = (action: any, data: any) => {
  merge(action, data)
}

const duplicateOrder = async () => {
  loading.value = true

  try {
    const newOrder = await $api.v1.rfqs.duplicate(
      props.order.id as number,
      { move_emails: moveEmailsToDuplicate.value },
    )

    adminStore.addOrder(newOrder)
    adminStore.activateOrder(newOrder)
    $toast.success('Order duplicated')
    duplicateOrderDialog.value = false
    emit('refresh-component')
  }
  catch (error) {
    $error.report(error)
  }

  loading.value = false
}
</script>

<style scoped lang="scss">
.action-button {
  pointer-events: auto;
}
</style>
