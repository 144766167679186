<template>
  <div class="pa-5">
    <v-expansion-panels v-model="openTabs" multiple>
      <v-expansion-panel v-for="source in attachmentSources" :key="source.key" class="qtm-border" elevation="0">
        <v-expansion-panel-title>
          <div class="text-secondary qtm-h3" v-text="source.title" />
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pt-5">
          <order-attachments
            v-model="source.attachments"
            allow-preview
            :content-type="source.contentType"
            :no-upload="source.noUpload"
            :object-id="source.objectId"
            remove-title
            @preview="$emit('preview', $event)"
          />
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel class="qtm-border" elevation="0">
        <v-expansion-panel-title>
          <div class="qtm-h3 text-secondary">
            Emails
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pt-5">
          <ul>
            <li v-for="email in emailAttachments" :key="email.id" class="mb-3">
              <h4 class="d-inline">
                {{ email.subject }}
              </h4>
              <span class="text-grey text--darken-1">
                ({{ getType(email.email_type) }}:
                {{ dateTime(email.date_created) }})
              </span>
              <order-attachments
                v-model="email.attachments"
                :no-delete="true"
                :no-upload="true"
                :remove-title="true"
                allow-preview
                @preview="$emit('preview', $event)"
              />
            </li>
          </ul>
          {{ emailAttachments.length === 0 ? "No attachments" : "" }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { dateTime } from '~/models/filters'
import OrderAttachments from '@/components/orders/order-attachments.vue'

const PO_CATEGORY = 2

export default {
  name: 'view-attachments',
  components: { OrderAttachments },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  emits: ['preview'],
  data() {
    return {
      loading: true,
      openTabs: [0, 1, 2, 3, 5, 6, 7, 8, 9, 10],
      emailAttachments: []
    }
  },
  computed: {
    attachmentSources() {
      const sources = [
        {
          key: 'order',
          title: 'Order',
          attachments: this.order.attachments,
          contentType: 'rfqs.order',
          objectId: this.order.id,
        },
      ]

      this.winningQuotes.forEach(quote => {
        sources.push({
          key: `quote-${quote.id}`,
          title: `Quote (${quote.vendor.name})`,
          attachments: quote.attachments,
          contentType: 'rfqs.quote',
          objectId: quote.id,
        })
      })

      this.order.pos.forEach(po => {
        const attachments = po.attachments.po_attachments.filter(attachment => attachment.category === PO_CATEGORY)

        if (attachments.length) {
          sources.push({
            key: `po-${po.id}`,
            title: `Purchase Order (${po.po_number})`,
            attachments,
            noUpload: true,
          })
        }
      })

      this.order.pos.forEach(po => {
        if (po.attachments.receipt_attachments.length) {
          sources.push({
            key: `receiving-${po.id}`,
            title: 'Receiving',
            attachments: po.attachments.receipt_attachments,
            noUpload: true,
          })
        }
      })

      this.order.invoices?.forEach(invoice => {
        sources.push({
          key: `invoice-${invoice.id}`,
          title: `Invoice (#${invoice.invoice_number})`,
          attachments: invoice.attachments,
          contentType: 'rfqs.invoice',
          objectId: invoice.id,
        })
      })

      this.order.pos.forEach(po => {
        sources.push({
          key: `other-${po.id}`,
          title: 'Other Supporting Documents',
          attachments: po.attachments.po_attachments.filter(attachment => attachment.category !== PO_CATEGORY),
          contentType: 'rfqs.purchaseorder',
          objectId: po.id,
        })
      })

      return sources
    },
    winningQuotes() {
      return this.order.quotes.filter(q => q.is_winning_quote)
    },
  },
  mounted() {
    this.fetchEmails()
  },
  methods: {
    dateTime(value) {
      return dateTime(value)
    },
    async fetchEmails() {
      this.loading = true
      try {
        const emails = await this.$api.v1.notifications.email.listByRfq(this.order, { emailType: 'attachments' })
        this.emailAttachments = emails.filter(email => email.attachments.length)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    getType(type) {
      return type === 'inbound' ? 'Received' : 'Sent'
    },
  }
}
</script>
<style scoped>
</style>
