<template>
  <qtm-skeleton v-if="loading" />
  <qtm-content-block v-else>
    <v-row>
      <v-col class="py-5" cols="3">
        <qtm-input-label label="User type">
          <qtm-select
            v-model="filter"
            single-line
            :items="['All', 'Staff', 'Customer']"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-timeline density="compact" side="end">
      <order-event-timeline-item v-for="log in filteredLogs" :key="log.id" :event="log" :now="now" />
      <v-timeline-item v-if="!logs.length" fill-dot size="small">
        No events have been recorded for this order
      </v-timeline-item>
    </v-timeline>
  </qtm-content-block>
</template>

<script setup lang="ts">
import { DateTime, type Order } from '@quotetome/materials-api'
import OrderEventTimelineItem from '@/components/admin/order-event-timeline-item.vue'

export interface Props {
  now: DateTime
  order: Order
  visible: boolean
}

const props = defineProps<Props>()

interface Log {
  id: string
  action: string
  context: any
  timestamp: DateTime
  user: any
  is_customer_event: boolean
}

const filter = ref('All')
const loading = ref(false)
const logs = ref<Log[]>([])

const filteredLogs = computed(() => {
  if (filter.value === 'Staff') {
    return logs.value.filter((l: any) => !l.is_customer_event)
  }

  if (filter.value === 'Customer') {
    return logs.value.filter((l: any) => l.is_customer_event)
  }

  return logs.value
})

const { $api, $error } = useNuxtApp()
const fetchAll = async () => {
  loading.value = true

  const [eventLogs, receiptLogs] = await Promise.all([
    fetchLogs(),
    fetchReceipts(),
  ])
  const fetchedLogs: Log[] = eventLogs.concat(receiptLogs)

  fetchedLogs.sort((a, b) => b.timestamp.compare(a.timestamp))
  logs.value = fetchedLogs
  loading.value = false
}
const fetchLogs = async () => {
  let fetchedLogs = []

  try {
    fetchedLogs = await $api.v1.rfqs.eventLog(props.order)
  }
  catch (error) {
    $error.report(error)
  }

  return fetchedLogs
}
const fetchReceipts = async () => {
  const po = props.order.pos[0]
  let receipts: Log[] = []

  if (!po) {
    return receipts
  }

  try {
    const receiptLogs = await $api.v1.purchaseOrders.receipts.list(po.id as number)

    receipts = receiptLogs.map((log: any) => ({
      id: `r-${log.id}`,
      action: 'Order Received',
      context: log,
      timestamp: DateTime.fromUnix(log.date_created),
      user: log.user,
      is_customer_event: !log.user.is_staff,
    }))
  }
  catch (error) {
    $error.report(error)
  }

  return receipts
}

watchEffect(() => {
  if (props.visible) {
    fetchAll()
  }
})
</script>
