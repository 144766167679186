<template>
  <items-btn label="Create" v-bind="$attrs" :items="validActions">
    <template v-slot:item-icon.get-quotes>
      <q-icon class="mr-2" />
    </template>
  </items-btn>
  <import-project-dialog v-model="showDialog" :companies="integratedCompanies" />
</template>

<script setup lang="ts">
import ItemsBtn from '@/components/items-btn.vue'
import QIcon from '@/components/q-icon.vue'

import ImportProjectDialog from '@/components/accounting/import-project-dialog.vue'
import type { Company } from '@quotetome/materials-api'

const { $api } = useNuxtApp()
const authStore = useAuthStore()
const router = useRouter()

const auth = computed(() => ({
  canGetQuotes: authStore.canGetQuotes,
  canCreatePO: authStore.canCreatePO,
  canManageCompany: authStore.canManageCompany,
  canManageInvoices: authStore.canManageInvoices,
  canUseExpenses: authStore.canUseExpenses,
  canUseRequisitions: authStore.canUseRequisitions,
}))

const integratedCompanies = ref<Company[]>([])
const showDialog = ref(false)

const actions = computed(() => {
  return [
    {
      label: 'Get Quotes',
      dataTest: 'get-quotes-link',
      auth: auth.value.canGetQuotes,
      to: { name: 'orders-new' },
      value: 'get-quotes',
    },
    {
      label: 'Create PO',
      icon: 'mdi-shopping-outline',
      dataTest: 'create-po-link',
      auth: auth.value.canCreatePO,
      to: { name: 'purchase-orders-new' },
      value: 'create-po',
    },
    {
      label: 'Create Expense',
      icon: 'mdi-currency-usd',
      dataTest: 'create-expense',
      auth: auth.value.canUseExpenses,
      to: { name: 'expenses-new' },
      value: 'create-expense',
    },
    {
      label: 'Create Requisition',
      icon: 'mdi-cart-outline',
      dataTest: 'create-requisition',
      auth: auth.value.canUseRequisitions,
      to: { name: 'requisitions-new' },
      value: 'create-requisition',
    },
    {
      label: 'Create Invoice',
      icon: 'mdi-file-document-outline',
      dataTest: 'create-invoice',
      auth: auth.value.canManageInvoices,
      to: { name: 'invoices-new' },
      value: 'create-invoice',
      new: true,
    },
    {
      label: 'Create Project',
      icon: 'mdi-format-list-checkbox',
      dataTest: 'create-project',
      auth: auth.value.canManageCompany,
      onClick: () => createProject(),
      value: 'create-project',
      class: 'separator',
    },
    {
      label: 'Create User',
      icon: 'mdi-account-outline',
      dataTest: 'create-user',
      auth: auth.value.canManageCompany,
      to: { name: 'users-new' },
      value: 'create-user',
    },
  ]
})

const validActions = computed(() => actions.value.filter((action) => action.auth))
const createProject = () => {
  if (integratedCompanies.value.length > 0) {
    showDialog.value = true
  }
  else {
    router.push({ name: 'projects-new' })
  }
}
onMounted(async () => {
  const companies = await $api.v1.companies.list()
  integratedCompanies.value = companies.filter(company => company.accounting_integration)
})
</script>
<style lang="scss">
.separator {
  border-top: 1px solid rgb(var(--v-theme-light-grey)) !important;
}
</style>
