<template>
  <v-btn
    v-if="order.canSendNotification"
    color="secondary"
    size="x-small"
    variant="outlined"
    @click="open"
  >
    Send Reminder
    <qtm-dialog-card v-model="confirm" title="Send Reminder Notification?">
      A reminder notification will be sent to
      <qtm-autocomplete v-model="user" hide-details :item-title="userName" :items="users" :loading="loading" />
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" tertiary @click="confirm = false">
          Cancel
        </qtm-btn>
        <qtm-btn :loading="loading" @click="sendNotification">
          Ok
        </qtm-btn>
      </template>
    </qtm-dialog-card>
  </v-btn>
</template>

<script setup lang="ts">
import type { Order, User } from '@quotetome/materials-api'

const props = defineProps<{
  order: Order
}>()

const confirm = ref(false)
const loading = ref(false)
const user = ref(props.order.owner?.id)
const users = ref<User[]>([])

const { $api, $error, $toast } = useNuxtApp()
const fetchTeam = async () => {
  loading.value = true
  try {
    const responseUsers = await $api.v1.users.list({ jobsite: props.order.jobsite?.id })

    responseUsers.sort(
      (a: any, b: any) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name)
    )
    users.value = responseUsers
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const open = () => {
  if (props.order.jobsite) {
    fetchTeam()
  }
  else if (props.order.owner) {
    users.value = [props.order.owner]
  }
  confirm.value = true
}

const sendNotification = async () => {
  loading.value = true
  try {
    await $api.v1.rfqs.sendStateNotification(props.order.id as number, { user: user.value })
    $toast.success('Notification sent')
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
  confirm.value = false
}

const userName = (localUser?: number | User) => {
  let item = localUser

  if (typeof user === 'number') {
    item = users.value.find(u => u.id === user)
  }

  if (!item) {
    return ''
  }

  item = item as User

  const append = item.id === props.order.owner?.id ? ' (default)' : ''

  return `${item.first_name} ${item.last_name}${append}`
}
</script>
