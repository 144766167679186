<template>
  <v-list-item class="admin-link-list-item" :href="url" target="_blank" v-bind="$attrs">
    <template v-if="withIcon" v-slot:prepend>
      <slot name="icon" />
    </template>

    <v-list-item-title>
      <slot />

      <v-icon color="mid-light-grey" size="x-small">
        mdi-open-in-new
      </v-icon>
    </v-list-item-title>
  </v-list-item>
</template>

<script setup lang="ts">
export interface Props {
  apiLink?: boolean
  to: string
  withIcon?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  apiLink: false,
  withIcon: false,
})

const { $api } = useNuxtApp()
const url = computed(() => $api.v1[props.apiLink ? 'getBackendURL' : 'getAdminURL'](props.to))
</script>

<style scoped lang="scss">
.v-icon {
  margin-left: 3px;
  position: relative;
  top: -5px;
}
</style>
