<template>
  <v-app-bar
    color="white"
    position="fixed"
    flat
  >
    <template v-slot:prepend>
      <nuxt-link :to="{name: 'index'}">
        <v-img alt="QuoteToMe Logo" class="qtm-logo" contain :src="qtmLogo" width="138" />
      </nuxt-link>

      <create-button
        v-if="!inSetupMode"
        class="ml-3"
      />
    </template>

    <v-spacer />

    <template v-if="isStaff">
      <nuxt-link class="nav-link" :to="{ name: 'admin-dashboard' }">
        Q-BOARD
      </nuxt-link>
      <nuxt-link class="nav-link" :to="{ name: 'admin-emails' }">
        Q-MAIL
      </nuxt-link>
      <emails-widget />
    </template>

    <v-spacer />

    <span v-if="inSetupMode" class="mr-5 text-secondary">
      Your company is in setup mode. Please complete your
      <nuxt-link class="text-decoration-none" :to="{ name: 'dashboard' }">
        setup tasks
      </nuxt-link>
      before you proceed.
    </span>

    <template v-else>
      <global-search density="compact" max-width="500" />
    </template>

    <slot name="append" />
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import CreateButton from '@/components/create-button.vue'
import EmailsWidget from '@/components/admin/emails/emails-widget.vue'
import GlobalSearch from '@/components/search/global-search.vue'
import qtmLogo from '~/assets/img/QTM_Logo_wTagline.svg'

export default {
  name: 'app-header',
  components: { EmailsWidget, GlobalSearch, CreateButton },
  computed: {
    ...mapState(useAuthStore, ['canCreatePO', 'canGetQuotes', 'inSetupMode', 'isStaff'])
  },
  created() {
    this.qtmLogo = qtmLogo
  },
  methods: {
    ...mapActions(useAdminStore, ['activateOrder']),
  }
}
</script>

<style lang="scss" scoped>
.qtm-logo {
  max-height: 29px;
  max-width: 138px;
  margin-right: 1rem;
}

.nav-link {
  color: rgb(var(--v-theme-mid-dark-grey));
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
}

.nav-link:hover {
  color: rgb(var(--v-theme-interactive));
}

/* https://nuxtjs.org/docs/2.x/features/nuxt-components/ */
.nuxt-link-exact-active {
  color: rgb(var(--v-theme-interactive));
}
</style>
